import React, {useContext, useEffect, useRef} from 'react'
import classes from './map_view.module.css';

import { _ } from '../../Constants';


import { GetPath} from '../../utils/utils';
import { MapContext } from '../../utils/MapContext';
import Image from './img';
import PinchZoomPan from '../../PinchToZoom';
import { RouterContext, Routes } from '../../utils/RouterContext';
import { AppContext } from '../../utils/AppContext';


const MapView = ({children, entry}) => {
  const zoomableRef = useRef();
  const mapCtx = useContext(MapContext);
  const {db} = useContext(AppContext);  
  const {route,IsRoute, SetRoute} = useContext(RouterContext);
  
  const mapData = mapCtx?.mapObject;
  const ref = useRef();
    
  useEffect(() => {
    if(IsRoute(Routes.idle))
      mapCtx?.SetMapObject({})    
  }, [route]);
  
  let settings  = {scale:1, postition:{x:0,y:0}}
  if(entry?.mapSettings != null)
    settings = entry.mapSettings;
  
  const showMapLinks = !IsRoute(Routes.intro) && !IsRoute(Routes.home) && !IsRoute(Routes.idle);

  return (
    <div className={"fullscreen "+classes.root} ref={ref}>
      <PinchZoomPan isActive={!IsRoute(Routes.intro) && !IsRoute(Routes.home) && !IsRoute(Routes.idle) && settings.scale===1} width="100%" height="100%" initialScale={settings.scale} offset={{x:0,y:0}} scaleMidPoint={settings.position} id={JSON.stringify(settings)+(entry?.uuid?entry?.uuid+mapData?.img:mapData?.img)} className={classes.pinchToZoom} zoomableRef={zoomableRef}>
			    <div className={classes.zoomable} ref={zoomableRef}>
            <Image src="/content/map_bg.jpg" className={classes.bg}></Image>
            {!IsRoute(Routes.idle) &&<>
              <Image src={mapData?.img} className={classes.img} transition={true}></Image>
              <div className={classes.activeAreas}>
                {showMapLinks && (db.mapLinks.map((maplink)=>{                   
                  if(!IsRoute(maplink.settings.link))
                  return <img 
                    key={maplink.img} 
                    src={GetPath(maplink.img)}
                    style={{
                      position:'absolute',
                      left:(maplink.settings.left*100)+"%",
                      bottom:(maplink.settings.bottom*100)+"%",
                      width:(maplink.settings.width*100)+"%",
                      transform:'rotate('+maplink.settings.rotation+'deg)',
                      pointerEvents:'all'
                    }}
                    onMouseUp={(e)=>{SetRoute(maplink.settings.link)}}
                  ></img>
                }))}
                
              </div>
              <Image src={mapData?.overlay} className={classes.overlay}></Image>
              <div className={classes.children}>
                {children}
              </div>
            </>
            }
          </div>
 		 </PinchZoomPan>
    </div>
  );
}
export default MapView;