import React, { createContext, useState, useEffect, useCallback } from "react";

// create context
const StageContext = createContext();

const StageContextProvider = ({id, children }) => {
  const [stage_id, setStageId] = useState(null);
  const [state, setState] = useState(null);
  const [introIsPlaying, setIntroIsPlaying] = useState(false);
  const [openedLegend, setOpenedLegend] = useState(null);
  
  const [popup, SetPopup] = useState({subentry:null, page:null});
  const [subEntry, setSubEntry] = useState(null);

  useEffect(() => {
    setStageId(id);
    SetState(States.idle);
  },[]);
  
  const SetState = useCallback((_state) => {
    if(_state !== States.idle)
      setIntroIsPlaying(true)
    else if(_state === States.idle){
      setOpenedLegend(null);
      setIntroIsPlaying(false)
    }
    
    setState(_state);
  }, []);
  const SetSubEntry = useCallback((val) => {
    setSubEntry(val);
  }, []);

  const SetIntroIsPlaying = useCallback((val) => {
    setIntroIsPlaying(val);
  }, []);
  const SetOpenedLegend = useCallback((obj) => {
    setOpenedLegend(obj);
  }, []);
  
  return (
    <StageContext.Provider value={{stage_id,state, SetState, introIsPlaying, SetIntroIsPlaying, openedLegend, SetOpenedLegend, popup, SetPopup, subEntry, SetSubEntry}}>
      {children}
    </StageContext.Provider>
  );
};

const States = {
  'idle':'idle',
  'active':'active'
}

export { StageContext, StageContextProvider, States };
