import React from 'react';

const settle = (val, target, range) => {
  const lowerRange = val > target - range && val < target;
  const upperRange = val < target + range && val > target;
  return lowerRange || upperRange ? target : val;
};

const inverse = (x) => x * -1;

const getMidpoint = (pointA, pointB) => ({
    x: (pointA.x + pointB.x) / 2,
    y: (pointA.y + pointB.y) / 2,
});

const getDistanceBetweenPoints = (pointA, pointB) => (
  Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2))
);

const between = (min, max, value) => Math.min(max, Math.max(min, value));


const filterTouches = (touches, rect)=>{
  const ftouches = [];
  for(let i=0;i<touches.length;i++){
    if(touches[i].clientX>rect.left && touches[i].clientX<rect.right)
      ftouches.push({
        x: touches[i].clientX - rect.left,
        y: touches[i].clientY - rect.top,
      })
  }
  return ftouches;
}

class PinchZoomPan extends React.Component {
  constructor() {
		
		super(...arguments);
    this.SETTINGS = {
      MIN_SCALE:1,
      MAX_SCALE:4,
      SETTLE_RANGE:0.001,
      ADDITIONAL_LIMIT:0.01,
      DOUBLE_TAP_THRESHOLD:300,
      ANIMATION_SPEED:0.04,
      RESET_ANIMATION_SPEED:0.08,
      INITIAL_X:0,
      INITIAL_Y:0,
      
      OFFSET:{x:0, y:0},
		  INITIAL_SCALE:this.props.initialScale,
			MIN_SCALE:this.props.initialScale
    }
		this.lastTouchesAmount = 0;
		this.state = this.getInititalState();

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleTouchEnd = this.handleTouchEnd.bind(this);
		this.handleWheel = this.handleWheel.bind(this);
		
    this.rect={left:-1, right:-1,top:-1, bottom:-1, width:1, height:1}
  }

  zoomTo(scale, midpoint) {
    
    clearTimeout(this.to)
    this.to = 0;
    this.animation && cancelAnimationFrame(this.animation);
    const frame = () => {
      if (this.state.scale === scale) return null;
    
      const distance = scale - this.state.scale;
      const targetScale = this.state.scale + (this.SETTINGS.ANIMATION_SPEED * distance);
			//const targetX = this.state.x + (ANIMATION_SPEED * x-this.state.x);
     
      this.zoom(settle(targetScale, scale, this.SETTINGS.SETTLE_RANGE), midpoint);
      this.animation = requestAnimationFrame(frame);
      //this.to = setTimeout(frame.bind(this), 40);
      
    };
    
    //this.to = setTimeout(frame.bind(this), 40);
    this.animation = requestAnimationFrame(frame);
  }

  reset() {
    clearTimeout(this.to)
    this.to = 0;
    const frame = () => {
      if (this.state.scale === this.SETTINGS.INITIAL_SCALE && this.state.x === this.SETTINGS.INITIAL_X && this.state.y === this.SETTINGS.INITIAL_Y) return null;

      const distance = this.SETTINGS.INITIAL_SCALE - this.state.scale;
      const distanceX = this.SETTINGS.INITIAL_X - this.state.x;
      const distanceY = this.SETTINGS.INITIAL_Y - this.state.y;

      const targetScale = settle(this.state.scale + (this.SETTINGS.RESET_ANIMATION_SPEED * distance), this.SETTINGS.INITIAL_SCALE, this.SETTINGS.SETTLE_RANGE);
      const targetX = settle(this.state.x + (this.SETTINGS.RESET_ANIMATION_SPEED * distanceX), this.SETTINGS.INITIAL_X, this.SETTINGS.SETTLE_RANGE);
      const targetY = settle(this.state.y + (this.SETTINGS.RESET_ANIMATION_SPEED * distanceY), this.SETTINGS.INITIAL_Y, this.SETTINGS.SETTLE_RANGE);

      const nextWidth = this.rect?.width * targetScale;
      const nextHeight = this.rect?.height * targetScale;
			
      this.setState({
        x: targetX,
        y: targetY,
        scale: targetScale,
        width: nextWidth,
        height: nextHeight,
      }, () => {
        
       this.animation = requestAnimationFrame(frame);
       //this.to = setTimeout(frame.bind(this), 40);
      });
    };
    
    //this.to = setTimeout(frame.bind(this), 40);
    this.animation = requestAnimationFrame(frame);
  }

  getInititalState() {
    return {
      x: this.SETTINGS.INITIAL_X,
      y: this.SETTINGS. INITIAL_Y,
      scale: this.SETTINGS.INITIAL_SCALE,
      width: 0,
      height: 0,
    };
  }

  handleTouchStart(event) {	
    
    clearTimeout(this.to)	
    this.to = 0;
    this.animation && cancelAnimationFrame(this.animation);
    const touches = filterTouches(event.touches, this.rect);
    
    if (touches.length == 2) this.handlePinchStart(touches);
    else if (touches.length == 1) this.handleTapStart(touches);
  }

  handleTouchMove(event) {
    if(this.to)
      return;
    const touches = filterTouches(event.touches, this.rect);
    if (touches.length == 2) this.handlePinchMove(touches);
    else if (touches.length == 1) this.handlePanMove(touches);    
  }

  handleTouchEnd(event) {
    const touches = filterTouches(event.touches, this.rect);

    if (touches.length > 0) return null;
    
    if (this.state.scale > this.SETTINGS.MAX_SCALE) return this.zoomTo(this.SETTINGS.MAX_SCALE, this.lastMidpoint);
    if (this.state.scale < this.SETTINGS.MIN_SCALE) return this.zoomTo(this.SETTINGS.MIN_SCALE, this.lastMidpoint);

    if (this.lastTouchEnd && this.lastTouchEnd + this.SETTINGS.DOUBLE_TAP_THRESHOLD > event.timeStamp) {
      this.reset();	
    }

    this.lastTouchEnd = event.timeStamp;
    
  }

	handleWheel(event)
	{
		let scale = between(this.SETTINGS.MIN_SCALE - this.SETTINGS.ADDITIONAL_LIMIT, this.SETTINGS.MAX_SCALE + this.SETTINGS.ADDITIONAL_LIMIT, this.state.scale+event.deltaY*.001);
    if(scale<1)
      scale = 1;
		this.zoom(scale, {x:event.pageX, y:event.pageY});
	}

  handleTapStart(touches) {
    this.lastPanPoint = touches[0];
  }

  handlePanMove(touches) {

		if (this.state.scale === 1) return null;

		let lastStateZoom = this.lastTouchesAmount == 2;
		this.lastTouchesAmount = touches.length;

		//event.preventDefault();
	
		const point = touches[0];
		if(lastStateZoom)
		{
			this.lastPanPoint = point;
		}
    const nextX = this.state.x + point.x - this.lastPanPoint.x;
    const nextY = this.state.y + point.y - this.lastPanPoint.y;

    this.setState({
      x: between(this.rect?.width - this.state.width, 0, nextX),
      y: between(this.rect?.height - this.state.height, 0, nextY),
    });
    
    this.lastPanPoint = point;
  }

  handlePinchStart(touches) {
    const pointA = touches[0];
    const pointB = touches[1];
    this.lastDistance = getDistanceBetweenPoints(pointA, pointB);
  }

  handlePinchMove(touches) {
    //event.preventDefault();

    const pointA = touches[0];
    const pointB = touches[1];
    const distance = getDistanceBetweenPoints(pointA, pointB);
    const midpoint = getMidpoint(pointA, pointB);
    let scale = between(this.SETTINGS.MIN_SCALE - this.SETTINGS.ADDITIONAL_LIMIT, this.SETTINGS.MAX_SCALE + this.SETTINGS.ADDITIONAL_LIMIT, this.state.scale * (distance / this.lastDistance));
		if(scale<1)
      scale = 1;
		this.lastTouchesAmount = touches.length;
		
    this.zoom(scale, midpoint);

    this.lastMidpoint = midpoint;
    this.lastDistance = distance;
  }

  zoom(scale, midpoint) {
		
    const nextWidth = this.rect.width * scale;
    const nextHeight = this.rect.height * scale;
    const nextX = Math.min(
									0, 
									Math.max(
										this.rect?.width-nextWidth,
										this.state.x + (inverse(midpoint.x * scale) * (nextWidth - this.state.width) / nextWidth)
										)
									);
    const nextY = Math.min(
			0, 
			Math.max(
				this.rect?.height-nextHeight,
				this.state.y + (inverse(midpoint.y * scale) * (nextHeight - this.state.height) / nextHeight)
			)
		);
    
    this.setState({
      width: nextWidth,
      height: nextHeight,
      x: nextX,
      y: nextY,
      scale,
    });
  }

  componentDidUpdate(){
    if(this.rect.left == -1){      
      const rect = this.container.getBoundingClientRect();
      this.rect={left:rect.left, right:rect.right,top:rect.top, bottom:rect.bottom, width:rect.width, height:rect.height}
    }

    if(this.props.zoomableRef.current){
      
      this.props.zoomableRef.current.style.setProperty('--zoom', this.state.scale);
      this.props.zoomableRef.current.style.setProperty('--left', this.state.x+this.SETTINGS.OFFSET.x);
      this.props.zoomableRef.current.style.setProperty('--top', this.state.y+this.SETTINGS.OFFSET.y);
// style={{"--zoom":scale, "--left":x+"px", "--top":y+"px"}}
    //(this.state.x+this.SETTINGS.OFFSET.x, this.state.y+this.SETTINGS.OFFSET.y, this.state.scale)
    }
    
  }

  d__shouldComponentUpdate(props, state){
    if(JSON.stringify(state) !== JSON.stringify(this.state))
      return true;
    if(props.id !== this.props.id)
      return true;
    return false;
  }

	render() {
 
    if(this.props.id != this.lastId)
    {
      this.SETTINGS.INITIAL_SCALE = this.props.initialScale;
      if(this.props.offset!=null)
      {
        this.SETTINGS.OFFSET.x = this.props.offset.x;
        this.SETTINGS.OFFSET.y = this.props.offset.y;
      }else{
        this.SETTINGS.OFFSET.x = 0;
        this.SETTINGS.OFFSET.y = 0;
      }
      if(this.props.scaleMidPoint!=null)
      {
        this.SETTINGS.INITIAL_X = this.props.scaleMidPoint.x;
        this.SETTINGS.INITIAL_Y = this.props.scaleMidPoint.y;
      }else{
        this.SETTINGS.INITIAL_X = 0;
        this.SETTINGS.INITIAL_Y = 0;
      }

      
      this.lastId = this.props.id;
      this.reset();
    }
    
    return (
      <div 
        ref={(ref) => this.container = ref}
        onTouchStart={this.props.isActive?this.handleTouchStart:null}
        onTouchMove={this.props.isActive?this.handleTouchMove:null}
        onTouchEnd={this.props.isActive?this.handleTouchEnd:null}
        onWheel={this.props.isActive?this.handleWheel:null}
        className={this.props.className?this.props.className:""}
        style={{
          overflow: 'hidden',
          width: this.props.width,
          height: this.props.height,
 
        }}
      >
        {this.props.children} 
      </div>
    );		
	}
}
export default PinchZoomPan;