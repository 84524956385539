import React, {useContext} from 'react'
import classes from './content.module.css';

import { StageContext } from '../../utils/StageContext';
import { _ } from '../../Constants';

import { AppContext} from '../../utils/AppContext';
import Admin from './admin/admin';
import IdleView from '../idle/idle';
import IntroView from '../intro/intro';
import MapView from '../map_view/map_view';
import EntryView from './entry_view/entry_view';
import Popup from './popup/popup';
import EntryViewMapUi from './entry_view_map_ui/entry_view_map_ui';
import { RouterContext, Routes} from '../../utils/RouterContext';

const Content = () => {
  
  const db = useContext(AppContext).db;
  const {stage_id, state} = useContext(StageContext);
  const {route, IsRoute, Get} = useContext(RouterContext);

  const entry = db.GetMainEntryByRoute(route);

  const popup = db.GetPopupByRoute(route);
  return (
    <div className={classes.root+_+stage_id}>
      <MapView entry={entry} >
        {entry != null && !IsRoute(Routes.idle) && <EntryViewMapUi entry={entry}></EntryViewMapUi>}
      </MapView>
      {(IsRoute(Routes.home) || IsRoute(Routes.intro)) && <IntroView></IntroView>}
      {entry != null && !IsRoute(Routes.idle) && <EntryView entry={entry}></EntryView>}
      {popup && <Popup popup={popup}></Popup>}
      <IdleView></IdleView>
      <Admin></Admin>
    </div>
  );
}
export default Content;
