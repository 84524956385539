import React, {useContext} from 'react'
import classes from './footer.module.css';


import { StageContext, States } from '../../utils/StageContext';
import { _ } from '../../Constants';
import { LanguageContext} from '../../utils/LanguageContext';
import { AppContext, States as AppStates } from '../../utils/AppContext';
import { useState } from 'react';


import ico_legend from './../../images/icons/legend.svg'
import ico_imprint from './../../images/icons/imprint.svg'
import ico_help from './../../images/icons/help.svg'
import ico_home from './../../images/icons/home.svg'
import ico_menu from './../../images/icons/menu.svg'
import ico_admin from './../../images/icons/admin.svg'
import ico_close from './../../images/icons/close.svg'
import ico_close_blue from './../../images/icons/ico_close.png'

import Text from '../text/text';
import FooterDetails from './footer_details';
import { MapContext } from '../../utils/MapContext';
import { RouterContext, Routes } from '../../utils/RouterContext';
import { useEffect } from 'react';


const Footer = () => {
  const appCtx = useContext(AppContext);
  const appState = appCtx.state;
  const SetAppState = appCtx.SetState;
  const db = appCtx.db;
  const {stage_id, openedLegend} = useContext(StageContext);
  const {route, SetRoute, IsRoute} = useContext(RouterContext);
  const {language, setLanguage, get} = useContext(LanguageContext);
  const {mapObject} = useContext(MapContext);
  
  const [opened, setOpened] = useState(null);

  useEffect(()=>{
    document.addEventListener("footerEvent_"+stage_id, function (e) {
      if(e.detail.key === "home")
        e.detail.key= "menu"
      if(opened!==e.detail.key && e.detail.val){
        setOpened(e.detail.key);
      }else if(opened===e.detail.key && !e.detail.val){
        setOpened(null);
      }

    });

  },[])
  const menuEntryClicked = (evt, id)=>{  
    if(window.isTouch && evt.touches == null)
      return;
    evt.preventDefault();
    SetRoute("/"+id);    
  }

  const langClicked = (evt, lang)=>{
    if(window.isTouch && evt.touches == null)
      return;
    evt.stopPropagation();
    evt.preventDefault();
    setLanguage(lang);
    setOpened(null);
  }

  const closeClicked = (evt, id) =>{
    setOpened(null);
    if(appState === AppStates.admin_login)
      SetAppState(AppStates.kiosk);
  }
  const clicked = (evt, id)=> {
    if(window.isTouch && evt.touches == null)
      return;
    //evt.preventDefault();
    
    if(opened!==id){
      setOpened(id!=="home"?id:"menu");
    }else{
      if(id === "imprint"){
        if(appState === AppStates.admin_login)
          SetAppState(AppStates.kiosk);
      }
      setOpened(null);
    }
    

    //
  }

  /*if(openedLegend && opened != "legend")
    setOpened("legend");
*/
  //console.log(db.imprint.type)
   return (
    <div className={classes.root + _ + stage_id}>
      <div className={classes.label}>{language + _ + route}</div>
    
      {appState === AppStates.admin && stage_id === "left"?
        <div 
          className={classes.btn} 
          onMouseDown={(e)=>{SetAppState(AppStates.kiosk)}}
          onTouchEnd={(e)=>{SetAppState(AppStates.kiosk)}}
        ><img src={ico_close} alt="close" /></div>
      :
      <>
        {
          !IsRoute(Routes.idle) &&
          <>
            <div className={classes.btn}>
              <img 
                src={ico_home} 
                alt="home" 
                onMouseDown={(e)=>{clicked(e,"home");menuEntryClicked(e,"home")}}
                onTouchEnd={(e)=>{clicked(e,"home");menuEntryClicked(e, "home")}} 
              />
            </div>

            <div className={classes.btn}>
              <img 
                src={ico_legend} 
                alt="legend" 
                onMouseDown={(e)=>clicked(e,"legend")}
                onTouchEnd={(e)=>clicked(e,"legend")} 
              />
              { mapObject?.legend && 
               <FooterDetails opened={opened==="legend"} className={"legend"}>
                 <img className={"close " + classes.blue_close} src={ico_close_blue} alt="close" onMouseDown={(e)=>closeClicked(e,"legend")} onTouchEnd={(e)=>closeClicked(e,"legend")}  />
                 <img src={ mapObject?.legend} alt="legend"></img>
              </FooterDetails> 
              }
            </div>
            <div 
              className={classes.btn} 
              onMouseDown={(e)=>clicked(e, "menu")}
              onTouchEnd={(e)=>clicked(e, "menu")}
              >
              <img src={ico_menu} alt="menu" />
                <FooterDetails opened={opened==="menu"} type={"menu"} >
                  <div className={classes.menu}>
                    <h1>{get('menu')}</h1>
                    <img className={classes.menu_close} src={ico_close} alt="close" />
                    <div>
                    {
                      db.entries.map((entry,id)=>{
                        return <div key={id} 
                          onMouseDown={(e)=>{menuEntryClicked(e, entry.id)}}
                          onTouchEnd={(e)=>{menuEntryClicked(e, entry.id)}}
                          className={classes.menuEntry + _ + (route.indexOf("/"+entry.id) === 0?classes.menuEntry_active:_)}
                          >
                          {entry.menuTitle?.Get(language)}
                        </div>
                      })
                    }
                    </div>
                  </div> 
                </FooterDetails> 
              </div>
          </>        
        }
        <div className={classes.btn}>
          <div onMouseDown={(e)=>clicked(e,"language")} onTouchEnd={(e)=>clicked(e,"language")} className={classes.langDiv}>{get(language)}</div>
          <FooterDetails className={classes.languageSelector} opened={opened==="language" && !IsRoute(Routes.idle)} type="language">
            <div className={classes.languageSelectorContainer}>
              <div className={classes.btn} onMouseDown={(e)=>{langClicked(e,'de');}} onTouchEnd={(e)=>{langClicked(e,'de');}}>{get('de')}</div>
              <div className={classes.btn} onMouseDown={(e)=>{langClicked(e, 'en');}} onTouchEnd={(e)=>{langClicked(e,'en');}}>{get('en')}</div>
              <div className={classes.btn} onMouseDown={(e)=>{langClicked(e, 'fr');}} onTouchEnd={(e)=>{langClicked(e,'fr');}}>{get('fr')}</div>
              <div className={classes.btn} onMouseDown={(e)=>{langClicked(e, 'pl');}} onTouchEnd={(e)=>{langClicked(e,'pl');}}>{get('pl')}</div>
            </div>
          </FooterDetails>
        </div>
        <div className={classes.btn}><img src={ico_help} alt="help" onMouseDown={(e)=>clicked(e,"help")} onTouchEnd={(e)=>clicked(e,"help")} />
          <FooterDetails opened={opened==="help" && !IsRoute(Routes.idle)}  className={"help"}>
            <img className={"close " + classes.blue_close} src={ico_close_blue} alt="close" onMouseDown={(e)=>closeClicked(e,"help")} onTouchEnd={(e)=>closeClicked(e,"help")}  />
            {db.help.type === "files"?
              <img src={db.help.Get(language)} alt={db.help.Get(language)} />
            :
              <Text>{db.help.Get(language)}</Text>
            }
          </FooterDetails>          
        </div>

        <div className={classes.btn}><img src={ico_imprint} alt="imprint"  onMouseDown={(e)=>clicked(e,"imprint")} onTouchEnd={(e)=>clicked(e,"imprint")} />
          <FooterDetails opened={opened==="imprint" && !IsRoute(Routes.idle)} detailsStyle={{paddingBottom:'7rem'}}>
            <img className={"close " + classes.blue_close} src={ico_close_blue} alt="close" onMouseDown={(e)=>closeClicked(e,"imprint")} onTouchEnd={(e)=>closeClicked(e,"imprint")} />
            {db.imprint.type === "files"?
              <img src={db.imprint.Get(language)} alt={db.imprint.Get(language)} />
            :
              <Text>{db.imprint.Get(language)}</Text>
            }
            <div className={classes.btn + _ + classes.adminBtn} onMouseDown= {(e)=>{SetAppState(AppStates.admin_login)}} onTouchEnd= {(e)=>{SetAppState(AppStates.admin_login)}}><img src={ico_admin} alt="admin" /></div>
          </FooterDetails>
        </div>
        </>
     }
    </div>
  );
}

export default Footer;
