import React, {useContext} from 'react'
import classes from './entry_view_map_ui.module.css';
import { LanguageContext} from '../../../utils/LanguageContext';
import Text from '../../text/text';
import { _ } from '../../../Constants';
import { RouterContext } from '../../../utils/RouterContext';
import { AppContext } from '../../../utils/AppContext';

const EntryViewMapUi = ({entry}) => {
  const db = useContext(AppContext).db;
  const {language} = useContext(LanguageContext);
  const {route, SetRoute, Get} = useContext(RouterContext);

  const subentryClicked = (subentry, route) =>{
    
    if(subentry.popups?.length>0){
      let parts = route.split("/");
      if(parts.length>1 && parts[parts.length-2] == "popup"){
        parts[parts.length-1] = subentry.id;
        SetRoute(parts.join("/"));
      }
      else{
        SetRoute(route+"/popup/"+subentry.id)
      }
    }
    else if(subentry.entries?.length>0){
      SetRoute("/"+Get(0)+"/sub/"+subentry.id)
    }
    else if(subentry.link != null){
      SetRoute(subentry.link)
    }
  }
  
  let subEntry = db.GetSubByRoute(route);
  let list = subEntry?subEntry.entries:entry.subentries;
  let classNames = classes.subentry;
  if(subEntry)
    classNames += _ + classes.subsubentry;
  
  return (
    <div className={classes.root+" fullscreen"}>
      {list.map((subentry)=>{
        return(
          <div 
            key={subentry.uuid} 
            className={classNames} 
            onMouseUp={(e)=>{e.preventDefault();e.stopPropagation();subentryClicked(subentry, route)}}
            onTouchEnd={(e)=>{e.preventDefault();e.stopPropagation();subentryClicked(subentry, route)}}
            style={{'--x':subentry.position.x, '--y':subentry.position.y}}>
              <Text>{subentry.title.Get(language)}</Text>
          </div>
        )
      })}      
    </div>
  );
}
export default EntryViewMapUi;